import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png';

const Navbar = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navigation-wrap">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img decoding="async" src={Logo} alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-stream navbar-toggler-icon"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/" activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about" activeClassName="active">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact-us" activeClassName="active">
                  Contact Us
                </NavLink>
              </li>
              <li>
              <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free Number</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Cookie = () => {
  return (
    <>
    <Navbar />

<section id="explore-food">
 <div class="explore-food wrapper">
   <div class="container">
     <div class="row">
       <div class="col-sm-12">
         <div class="text-content text-center">
          <h2>Cookie Policy - Vahan Autos Salvage LLC</h2>
          <p>At VAHAN AUTO SALVAGE LLC, we use cookies on our website to enhance your online experience and to better understand how you use our services. This policy provides detailed information about how and why we use cookies and how you can manage your cookie preferences.</p>
          <h3>What are cookies?</h3>
          <p>Cookies are small text files that are placed on your device (computer, smartphone, or other electronic device) when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the site owners.</p>
         <h3>How we use cookies</h3>
         <p>We use cookies to:</p>
         <p>Improve your browsing experience by allowing our website to remember your actions and preferences (such as login details, language, font size, and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.</p>
         <p>Analyze our website traffic and understand how our users interact with our website, which helps us improve our website structure, design, content, and functions.</p>
         <p>Enhance the performance of our website by helping us understand which pages are the most and least popular and see how visitors move around the site.</p>
         <p>Targeting and advertising to tailor ads displayed to you both on and off our website to your interests.</p>
        
         </div>
       </div>
     </div>
   </div>
 </div>
</section>

<Footer />
    
    </>
  )
}

export default Cookie
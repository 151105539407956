import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Privacy = () => {
  return (
    <>
    <Navbar />

    <section id="explore-food">
     <div class="explore-food wrapper">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-content text-center">
              <h2>Privacy Policy</h2>
              <p>Vahan ensures the safety and security of your information when you complete an online transaction.</p>
              <p>By providing this Internet Privacy Statement, we wish to assure site visitors that any information about them that is obtained through the site will not be sold, shared, or rented to outsiders.</p>
             <p>We contact independent distributors and delivery companies to help us fulfill and deliver orders placed via our site. Our customers are billed with a credit card processing company, and independent distributors and delivery companies help us fulfil and deliver orders. Credit card information is only provided to them when it is needed to process orders, complete deliveries, and process credit cards.</p>
             <p>No personal information about our users will be shared, sold, or rented to any outside company, including financial institutions and order fulfillment companies.</p>
             <p>Vahan Autoparts customers and users receive regular notifications and updates that contain important information.</p>
             <p>You can always amend the personally identifiable information that you have provided to Quality at any time by emailing us at info@vahanautossalvage.com</p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>

    <Footer />
    </>
  )
}

export default Privacy
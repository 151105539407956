import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import successImage from '../../images/success-checkmark.png';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import '../payments/Success.css'

const stripePromise = loadStripe('YOUR_STRIPE_PUBLIC_KEY'); // Replace with your Stripe public key

const Success = () => {
  const [transactionNumber, setTransactionNumber] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  useEffect(() => {
    const fetchSessionDetails = async (sessionId) => {
      try {
        const stripe = await stripePromise;
        const session = await stripe.retrieveSession(sessionId);
        setTransactionNumber(session.payment_intent);
        setAmountPaid((session.amount_total / 100).toFixed(2)); // Stripe amounts are in cents
        setPaymentMethod(session.payment_method_types[0]); // Assuming single payment method
      } catch (error) {
        console.error('Error fetching session details:', error);
      }
    };

    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get('session_id');

    if (sessionId) {
      fetchSessionDetails(sessionId);
    }
  }, []);

  return (
    <>
      <Navbar />
      <section className="success-section" id="success">
        <div className="sec-content">
          <div className="sec-title text-center">
            <h2>Booking Confirmation</h2>
          </div>
          <div className="success-content text-center">
            <div className="success-icon">
              <img src={successImage} alt="Success" className="success-checkmark" />
            </div>
            <div className="success-message">
              <h3>Order Payment Successful</h3>
              <p>Transaction Number: {transactionNumber}</p>
              <hr />
              <p>Amount paid <span className="amount-paid">${amountPaid}</span></p>
              <p>Paid by <span className="payment-method">{paymentMethod}</span></p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Success;

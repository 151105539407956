import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Warranty from './pages/Warranty';
import Shipping from './pages/Shipping';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import Cookie from './pages/Cookie';
import Copyright from './pages/Copyright';
import { Hosted } from './file/stripe/Hosted';
import './App.css';
import Success from './file/payments/Success';

function App() {
  return (
    <div className="App">
    <Router>
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/warranty-policy" element={<Warranty />} />
          <Route path="/shipping-policy" element={<Shipping />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/copyright-policy" element={<Copyright />} />
          <Route path="/payment/to" element={<Hosted />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      
    </Router>
    </div>
  );
}

export default App;

import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-5">
                <div className="footer-social pt-4 text-center">
                  <p>
                    VAHAN AUTOS SALVAGE LLC stands as your comprehensive hub for
                    vehicle components, catering to every automotive need with the
                    most extensive array of auto parts, import pieces, and
                    accessories. At your fingertips, you'll find premium quality
                    parts, from OEM to aftermarket options, alongside meticulously
                    refurbished and rebuilt components sourced from renowned
                    manufacturers and suppliers online. Renowned for being the
                    go-to digital storefront for automotive parts, VAHAN AUTO
                    SALVAGE LLC assures not just top-tier quality at wholesale
                    rates, but also the assurance of a full warranty on our
                    products. With catalogs that are constantly refreshed to
                    include the latest and most dependable brands in the industry,
                    we make searches for “auto parts near me” or “car parts and
                    car accessories near me” simple and successful. Experience the
                    ease and comfort of shopping from home, a service unmatched by
                    conventional car part shops, with VAHAN AUTO SALVAGE LLC.
                  </p>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <NavLink className="footer-link" to="/warranty-policy">
                  Warranty Policy
                </NavLink>
                <NavLink className="footer-link" to="/shipping-policy">
                  Shipping Policy
                </NavLink>
                <NavLink className="footer-link" to="/privacy-policy">
                  Privacy Policy
                </NavLink>
                <NavLink className="footer-link" to="/terms-conditions">
                  Terms & Conditions
                </NavLink>
                <NavLink className="footer-link" to="/refund-policy">
                  Refund Policy
                </NavLink>
                <NavLink className="footer-link" to="/cookie-policy">
                  Cookie Policy
                </NavLink>
                <NavLink className="footer-link" to="/copyright-policy">
                  Copyright Policy
                </NavLink>
              </div>
              <div class="row pt-5">
           <div class="col-sm-6 mb-4">
            <h4 className='text-white'>Address:</h4>
            <p className='text-white'>30 N Gould ST STE R, Sheridan, WY 82801, USA</p>
            <h4 className='text-white'>Contact Us:</h4>
            <p className='text-white'>+1 (844) 661-0254</p>
           </div>
           <div class="col-sm-6 mb-4">
            <h4 className='text-white'>Email Us:</h4>
            <p className='text-white'>support@vahanautossalvage.com</p>
           </div>
         </div>
              <div className="col-sm-12">
                <div className="footer-copy">
                  <div className="copy-right text-center pt-5">
                    <p className="text-light">
                      © 2024. Vahan Autos Salvage LLC. All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

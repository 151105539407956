import React, { useState, useEffect } from "react";
import "../paypal/paypal.css";
import book from "../../images/logo.png";
import axios from "axios";

const ProductDisplay = () => {
  const HOST_API = process.env.REACT_APP_HOST_API_KEY || "";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState(""); 
  const [productName, setProductName] = useState("Vahan Autos Salvage LLC");
  const [amount, setAmount] = useState(20);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${HOST_API}/hosted/create-checkout-session`, {
        name,
        email,
        postalCode, // Include postal code in the payload
        product_name: productName,
        amount,
      });
      console.log(response.data);
      setSubmissionMessage("Checkout successful! Redirecting...");
      window.location.href = response.data.sessionUrl; // Redirect using href
    } catch (error) {
      console.error("Checkout error", error);
      setSubmissionMessage("Failed to process checkout. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="product-display">
    <section>
    <div className="product-header">
          <img src={book} alt="Logo" className="product-logo" />
          <div className="product">
            <div className="description">
              <h3>{productName}</h3>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Price"
                required
                min="1"
              />
            </div>
          </div>
        </div>

      <form className="checkout-form" onSubmit={handleSubmit}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        <input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="Postal Code" required />
        <button type="submit" className="submit-btn" disabled={isSubmitting}>{isSubmitting ? "Processing..." : "Checkout"}</button>
      </form>

      {submissionMessage && <p>{submissionMessage}</p>}
    </section>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export const Hosted = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? <Message message={message} /> : <ProductDisplay />;
};

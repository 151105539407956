import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Shipping = () => {
  return (
    <>
    <Navbar />

    <section id="explore-food">
     <div class="explore-food wrapper">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-content text-center">
              <h2>Shipping Policy</h2>
              <p><b>All orders are shipped within 10-15 days. The carriers we use to deliver our orders are USPS, UPS & FedEx.</b></p>
              <p>Order Tracking: If a tracking number is provided by the shipping carrier, we will update your order with the tracking information. Please note that some orders using 1st Class USPS mail will not have tracking numbers.</p>
             <p>Shipping Rates: The rate charged for the shipping of your order is based on the weight of your products, and your location. Before the final checkout page you will be shown what the cost of shipping will be, and you will have a chance to not place your order if you decide not to.</p>
             <p>Back Order: If an item goes on back order we will ship you the part of your order that is in stock. When the item becomes available we will ship you the rest of your order. You will not be charged any additional shipping and handling for the second shipment.</p>
             
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>

    <Footer />
    </>
  )
}

export default Shipping
import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Refund = () => {
  return (
    <>
    <Navbar />

<section id="explore-food">
 <div class="explore-food wrapper">
   <div class="container">
     <div class="row">
       <div class="col-sm-12">
         <div class="text-content text-center">
          <h2>Return Policy - Vahan Autos Salvage LLC</h2>
          <p>At Vahan Autos Salvage LLC, we are committed to ensuring satisfaction with every purchase. We understand that sometimes, a product may not be exactly what you needed, which is why we have a straightforward return policy.</p>
          <h3>Eligibility</h3>
          <p>To be eligible for a return, items must be in the condition you received them—unused, with original packaging and labels. Products that have been installed, used, or altered are not eligible for return. Please ensure that the item you are returning is repackaged with all the cords, adapters, and documentation that were included when you received it.</p>
         <h3>Return Period</h3>
         <p>Our return window is 30 days from the date of delivery. If 30 days have gone by since your purchase arrived, unfortunately, we cannot offer you a refund or exchange.</p>
         <h3>Return Process</h3>
         <p>Contact our customer support team at support@vahanautoparts.com or call us at Toll Free Number (844) 243-8423 with your order number and details about the product you wish to return. We will provide you with a Return Merchandise Authorization (RMA) number.</p>
         <p>Place the item securely in its original packaging, and include your proof of purchase and the RMA number.</p>
         <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
         <p>We recommend using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.</p>
         <h3>Refund Methods</h3>
         <p>Refunds will be processed to the original method of payment used at the time of purchase.</p>
         <h3>Refund Timeframe</h3>
         <p>Once your return is received and inspected, we will initiate your ruturn within 24 hours.</p>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>

<Footer />
    
    </>
  )
}

export default Refund
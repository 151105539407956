import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    <>
    <Navbar />

<section id="explore-food">
 <div class="explore-food wrapper">
   <div class="container">
     <div class="row">
       <div class="col-sm-12">
         <div class="text-content text-center">
          <h2>Terms & Conditions - Vahan Autos Salvage LLC</h2>
          <h3>Import Fees and International Shipments</h3>
          <p>Items shipped to countries outside of the U.S. may be subject to taxes, customs duties, and fees levied by the destination country (“Import Fees”). The recipient of the shipment is the importer of record in the destination country and is responsible for all Import Fees.</p>
          <p>For each item with calculated Import Fees, you authorize Vahan Autos Salvage LLC to designate a carrier (“Designated Carrier”) to act as your agent with the relevant customs and tax authorities in the destination country. This carrier will clear your merchandise, process, and remit your actual Import Fees.</p>
         <p>The “Import Fees Deposit” represents an estimate of the Import Fees that will be levied on the items in your order for shipment outside of the U.S. By placing your order, you agree to allow Vahan Autos Salvage LLC to collect this deposit. It will be used to reimburse the Designated Carrier for the Import Fees paid on your behalf to the appropriate authorities.</p>
         <p>You agree that the Designated Carrier may disclose to Vahan Autos Salvage LLC the amount of actual Import Fees levied on the item you have purchased. If the Import Fees Deposit exceeds the actual Import Fees, Vahan Autos Salvage LLC will refund the difference to you.</p>
         <p>In the case of gifts or other purchases made on behalf of another recipient, you also agree to grant these authorizations on behalf of the recipient designated in your order. For details regarding the Actual Import Fees or to obtain documentation or receipts related to customs clearance, you may contact the Designated Carrier specified in your shipment confirmation.</p>
         <p>These terms and conditions supplement the standard Conditions of Use of the Vahan Autos Salvage LLC website. Pursuant to those terms, title and risk of loss for the items transfer to the recipient upon delivery to the common carrier in the United States.</p>
         <h3>Limitations</h3>
         <p>For products shipped internationally, please note:</p>
         <li>Any manufacturer warranty may not be valid;</li>
         <li>Manufacturer service options may not be available;</li>
         <li>Product manuals, instructions, and safety warnings may not be in the destination country's language;</li>
         <li>Products (and accompanying materials) may not be designed in accordance with destination country standards, specifications, and labeling requirements;</li>
         <li>Products may not conform to destination country voltage and other electrical standards (an adapter or converter may be required).</li>
         <p>You are responsible for ensuring that the product can be lawfully imported into the destination country. When ordering from Vahan Autos Salvage LLC, the recipient is the importer of record and must comply with all laws and regulations of the destination country.</p>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>

<Footer />
    
    </>
  )
}

export default Terms
import React from 'react'
import Img1 from './images/img/img-1.png'
import Img2 from './images/img/img-2.png'
import Img3 from './images/img/parts1.jpg'
import Img4 from './images/img/Headlights-Lighting.jpg'
import Img5 from './images/img/Brakes-Suspension-Steering.jpg'
import Img6 from './images/img/Engine-Drivetrain.jpg'
import Img7 from './images/img/Interior-Accessories.jpg'
import Img8 from './images/img/Exterior-Accessories.jpg'
import Img9 from './images/img/why.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Review1 from './images/review/review-1.jpg'
import Review2 from './images/review/review-2.jpg'
import Review3 from './images/review/review-3.jpg'

const Home = () => {
  return (
    <>
    <body>
  {/* <!-- header design --> */}
  <Navbar />
   
  {/* <!-- section-1 top-banner --> */}
  <section id="home">
    <div class="container-fluid px-0 top-banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6">
            <h1>ALWAYS SAVE UP TO 30% FROM OVER 10 MILLION VAHAN AUTOS</h1>
            <p>Locate High-Quality Used Auto Parts and Nearby Junkyards.
            </p>
            <div class="mt-4">
              <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              {/* <button class="white-btn ms-lg-4 mt-lg-0 mt-4">Order now <i class="fas fa-angle-right ps-3"></i></button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* <!-- section-2 counter --> */}
  <section id="counter">
    <section class="counter-section">
      <div class="container">
      <div class="text-center pb-4">
           <h2>Best deal with purchase of Auto Parts</h2>
         </div>
        <div class="row text-center">
          <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
            <h2>
              <span id="count1">200</span>+
            </h2>
            <p>Junkyards Connections</p>
          </div>
          <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
            <h2>
              <span id="count2">9470</span>+
            </h2>
            <p>Happy Clients</p>
          </div>
          <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
            <h2>
              <span id="count3">6</span>+
            </h2>
            <p>Month Warrenty</p>
          </div>
          <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
            <h2>
              <span id="count4">175</span>+
            </h2>
            <p>Inspection Point</p>
          </div>
        </div>
      </div>
    </section>
  </section>

  {/* <!-- section-3 about--> */}
   <section id="about">
     <div class="about-section wrapper">
       <div class="container">
         <div class="row align-items-center">
           <div class="col-lg-7 col-md-12 mb-lg-0 mb-5">
             <div class="card border-0">
               <img decoding="async" src={Img1} class="img-fluid" />
             </div>
           </div>
           <div class="col-lg-5 col-md-12 text-sec">
            <h2>About Us - Vahan Autos Salvage LLC</h2>
            <p>Welcome to Vahan Autos Salvage LLC, your ultimate destination for all automotive needs. We pride ourselves on being the most comprehensive hub for vehicle components, offering an extensive array of auto parts, import pieces, and accessories. Whether you're seeking OEM parts, aftermarket options, or meticulously refurbished and rebuilt components, we've got you covered.</p>
              <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
           </div>
         </div>
       </div>
       <div class="container food-type">
         <div class="row align-items-center">
           <div class="col-lg-5 col-md-12 text-sec mb-lg-0 mb-5">
            <h2>At Vahan Autos Salvage LLC</h2>
            <p>Quality is our top priority. We source our parts from renowned manufacturers and suppliers, ensuring you receive only the best. Our digital storefront is renowned for providing top-tier quality at wholesale rates, with the added assurance of a full warranty on our products. Our catalogs are constantly updated to feature the latest and most dependable brands in the industry, making it easier than ever to find exactly what you need.</p>
            <ul class="list-unstyled py-3">
              <li>Extensive Selection: Offering a vast range of auto parts, from OEM and aftermarket options to meticulously refurbished components, ensuring we meet every automotive need.</li>
              <li>Quality Assurance: Sourcing from renowned manufacturers and suppliers, we provide top-tier parts at wholesale rates, backed by a full warranty for your peace of mind.</li>
              <li>Convenient Shopping: Our constantly updated digital storefront makes finding and purchasing auto parts effortless, providing an unmatched shopping experience from the comfort of your home.</li>
            </ul> 
            <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
           </div>
           <div class="col-lg-7 col-md-12">
             <div class="card border-0">
               <img decoding="async" src={Img2} class="img-fluid" />
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>

  {/* <!-- section-3 story--> */}
   <section id="story">
     <div class="story-section">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-content">
              <h2>Best deal with purchase of Auto Parts.</h2>
              <p>Call Us to get an instant quotation. Our Team will be in touch with you shortly to take it forward.</p>
              <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>

  {/* <!-- section-4 explore food--> */}
   <section id="explore-food">
     <div class="explore-food wrapper">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-content text-center">
              <h2>Shop by Categories</h2>
              <p></p>
             </div>
           </div>
         </div>
         <div class="row pt-5">
           <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
             <div class="card">
               <img decoding="async" src={Img3} class="img-fluid" />
               <div class="pt-3">
                <h4>Auto Body Parts & Mirrors</h4>
                <p>Bumpers, Fenders, Fenders, Mirrors, Hoods, Door Handles, Door Handles, Locks, Windows & Components</p>
                {/* <span>$10.50 <del>$11.70</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
             </div>
           </div>
           <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
            <div class="card">
              <img decoding="async" src={Img4} class="img-fluid" />
              <div class="pt-3">
                <h4>Headlights & Lighting</h4>
                <p>Headlights, Headlights, Tail Lights, Back Up Lights, Fog Lights, Driving Lights Components & Accessories</p>
                {/* <span>$9.20<del>$10.50</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
            <div class="card">
              <img decoding="async" src={Img6} class="img-fluid" />
              <div class="pt-3">
                <h4>Engine & Drivetrain</h4>
                <p>Catalytic Converters, Radiators, Fans, Cooling Systems, Radiators, Headers, Manifolds Mufflers & Componentss</p>
                {/* <span>$12.50<del>$13.20</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
            </div>
          </div>
          
         </div>

         <div class="row pt-5">
           <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
             <div class="card">
               <img decoding="async" src={Img5} class="img-fluid" />
               <div class="pt-3">
                <h4>Brakes, Suspension & Steering</h4>
                <p>Brake Discs, Pads & Calipers, Shocks, Struts, Shocks, Struts, Control Arms, Thrust Arms & Components</p>
                {/* <span>$10.50 <del>$11.70</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
             </div>
           </div>
           <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
            <div class="card">
              <img decoding="async" src={Img7} class="img-fluid" />
              <div class="pt-3">
                <h4>Interior Accessories</h4>
                <p>Seats, Seat Covers, Carpet & Vinyl Floor Kits Floor Mats & Liners, Dash, Switches, Relays, Wiring & Components</p>
                {/* <span>$9.20<del>$10.50</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-lg-0 mb-5">
            <div class="card">
              <img decoding="async" src={Img8} class="img-fluid" />
              <div class="pt-3">
                <h4>Exterior Accessories</h4>
                <p>Bumpers, Hoods, Grilles, Fog Lights, Driving Lights, Car Covers, Door Handles, Locks & Accessories</p>
                {/* <span>$12.50<del>$13.20</del></span> */}
                <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free <i class="fas fa-phone ps-3"></i></a>
              </div>
            </div>
          </div>
          
         </div>
       </div>
     </div>
   </section>


   

  {/* <!-- Section-5 testimonial--> */}
   <section id="testimonial">
     <div class="wrapper testimonial-section">
       <div class="container text-center">
         <div class="text-center pb-4">
           <h2>Testimonial</h2>
         </div>
         <div class="row">
          <div class="col-sm-12 col-lg-10 offset-lg-1">
            <div id="carouselExampleDark" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                  aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                  aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                  aria-label="Slide 3"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="carousel-caption">
                    <img decoding="async" src={Review1} />
                    <p>"I had a fantastic experience with Vahan Autos Salvage LLC. I found the exact part I needed for my car at an unbeatable price. The customer service team was incredibly helpful, and the part arrived quickly and in perfect condition. I highly recommend them! "</p>
                    <h5>John M., Texas</h5>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="carousel-caption">
                    <img decoding="async" src={Review2} />
                    <p>"Vahan Autos Salvage LLC exceeded my expectations. The quality of the refurbished parts was top-notch, and the 30-day warranty gave me peace of mind. I had a minor issue with my order, but their customer service handled it promptly and professionally. I will definitely be using them again for my future auto part needs."</p>
                    <h5> Sarah L., California</h5>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="carousel-caption">
                    <img decoding="async" src={Review3} />
                    <p>"I've been searching for a reliable source for auto parts, and I'm so glad I found Vahan Autos Salvage LLC. Their extensive selection and competitive prices are unmatched. Plus, the fast delivery and excellent customer support make them my go-to choice for all my automotive needs. Highly recommend! "</p>
                    <h5>Mike R., New York</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
     </div>
   </section>

  {/* <!-- section-6 faq--> */}
  <section class="services" id="services">
         <div class="sec-content">
             <div class="sec-title">
                <h2>Why Choose Us?</h2>
                <p>So much to offer</p>
             </div>
             <div class="service-content">
                 <div class="left">
                     <img decoding="async" src={Img9} alt="betheme" />
                 </div>
                 <div class="right">
                     <div class="card">
                         <div class="card-icon">
                         <i class="fas fa-shopping-cart"></i>
                         </div>
                         <div class="card-title">
                            <h5>Buy With Confidence</h5>
                            <p>We offer premium quality parts with a full warranty, ensuring you can shop with complete peace of mind.
                            </p>
                         </div>
                     </div>
                     <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-undo"></i>
                        </div>
                        <div class="card-title">
                           <h5>30 Days Returns</h5>
                           <p>Enjoy a hassle-free 30-day return policy, making your shopping experience worry-free and convenient.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-phone"></i>
                        </div>
                        <div class="card-title">
                           <h5>Call To Order</h5>
                           <p>Our knowledgeable team is ready to assist you with your orders, providing personalized support over the phone.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-shipping-fast"></i>
                        </div>
                        <div class="card-title">
                           <h5>Fast Delivery</h5>
                           <p>Experience swift and reliable delivery services, getting your parts quickly and efficiently to your doorstep.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-check"></i>
                        </div>
                        <div class="card-title">
                           <h5>Huge Selections</h5>
                           <p>Access an extensive range of auto parts, from OEM to aftermarket options, ensuring you find exactly what you need. 
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-dollar"></i>
                        </div>
                        <div class="card-title">
                           <h5>Competitive Prices</h5>
                           <p>Benefit from our wholesale rates on top-tier quality parts, offering you unbeatable value for your money.
                           </p>
                        </div>
                    </div>
                 </div>
             </div>
         </div>
     </section>

  {/* <!-- section-7 book-food--> */}
   <section id="book-food">
     <div class="book-food">
       <div class="container book-food-text">
         <div class="row text-center">
           <div class="col-lg-9 col-md-12">
            <h2>Get Best Deal With Purchase Of Auto Parts.</h2>
           </div>
           <div class="col-lg-3 col-md-12 mt-lg-0 mt-4">
           <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free</a>
           </div>
         </div>
       </div>
     </div>
   </section>

  {/* <!-- section-8 newslettar--> */}
  {/* <!-- section-6 faq--> */}
  <section id="faq">
     <div class="faq wrapper">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-center pb-4">
              <h2>Frequently Asked Questions</h2>
             </div>
           </div>
         </div>
         <div class="row pt-5">
           <div class="col-sm-6 mb-4">
            <h4><span>~</span>What types of auto parts do you offer?</h4>
            <p>We offer a comprehensive range of auto parts, including OEM, aftermarket options, and meticulously refurbished and rebuilt components. Our inventory includes parts for various makes and models, ensuring you find exactly what you need for your vehicle.
            </p>
           </div>
           <div class="col-sm-6 mb-4">
            <h4><span>~</span>How does your 30-day warranty work?</h4>
            <p>Our 30-day warranty covers damaged parts and products delivered erroneously. If a part is defective, we will first attempt to replace it. If a replacement is not possible, we will refund the entire purchase price. Please note that the warranty is parts-only and does not cover labor costs.
            </p>
           </div>
           <div class="col-sm-6 mb-4">
            <h4><span>~</span>What is your return policy?</h4>
            <p>We offer a hassle-free 30-day return policy. Parts must be returned within 30 days of the original shipping delivery date. Items modified or disassembled for testing are not returnable. Please contact our customer service department for an RMA before returning any parts.
            </p>
           </div>
           <div class="col-sm-6 mb-4">
            <h4><span>~</span>How can I place an order or get assistance with my purchase?</h4>
            <p>You can place an order directly through our online storefront or call our knowledgeable team for personalized support. We are available to assist you with any questions and ensure you find the right parts for your vehicle. Contact us at +1 (844) 661-0254 for assistance.


1/2







            </p>
           </div>
         </div>
       </div>
     </div>
   </section>
   {/* <section id="newslettar">
     <div class="newslettar wrapper">
       <div class="container">
         <div class="row">
           <div class="sol-sm-12">
             <div class="text-content text-center pb-4">
              <h2>Hurry up! Subscribe our newsletter
                and get 25% Off</h2>
              <p>Limited time offer for this month. No credit card required. </p>
             </div>
             <form class="newsletter">
               <div class="row">
                 <div class="col-md-8 col-12">
                   <input class="form-control" placeholder="Email Address here" name="email" type="email" />
                 </div>
                 <div class="col-md-4 col-12">
                   <button class="main-btn" type="submit">Subscribe</button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>
   </section> */}

  {/* <!-- section-9 footer--> */}
  <Footer />
   

  
</body>
    </>
  )
}

export default Home
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Img9 from '../images/img/why.jpg'

const Contact = () => {
  return (
    <>
      <Navbar />

      <section id="newslettar">
        <div className="newslettar wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-content text-center pb-4">
                  <h2>Quick Contact</h2>
                  <p>Fill in the details to get in touch with us</p>
                </div>
                <form className="newsletter">
                  <div className="row">
                    <div className="col-md-6 col-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Phone Number"
                        name="phone"
                        type="tel"
                        required
                      />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                      <input
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Your Query"
                        name="query"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <div className="col-md-12 col-12">
                      <button className="main-btn" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="services" id="services">
         <div class="sec-content">
             <div class="sec-title">
                <h2>Why Choose Us?</h2>
                <p>So much to offer</p>
             </div>
             <div class="service-content">
                 <div class="left">
                     <img decoding="async" src={Img9} alt="betheme" />
                 </div>
                 <div class="right">
                     <div class="card">
                         <div class="card-icon">
                         <i class="fas fa-shopping-cart"></i>
                         </div>
                         <div class="card-title">
                            <h5>Buy With Confidence</h5>
                            <p>We offer premium quality parts with a full warranty, ensuring you can shop with complete peace of mind.
                            </p>
                         </div>
                     </div>
                     <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-undo"></i>
                        </div>
                        <div class="card-title">
                           <h5>30 Days Returns</h5>
                           <p>Enjoy a hassle-free 30-day return policy, making your shopping experience worry-free and convenient.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-phone"></i>
                        </div>
                        <div class="card-title">
                           <h5>Call To Order</h5>
                           <p>Our knowledgeable team is ready to assist you with your orders, providing personalized support over the phone.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-shipping-fast"></i>
                        </div>
                        <div class="card-title">
                           <h5>Fast Delivery</h5>
                           <p>Experience swift and reliable delivery services, getting your parts quickly and efficiently to your doorstep.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-check"></i>
                        </div>
                        <div class="card-title">
                           <h5>Huge Selections</h5>
                           <p>Access an extensive range of auto parts, from OEM to aftermarket options, ensuring you find exactly what you need. 
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-dollar"></i>
                        </div>
                        <div class="card-title">
                           <h5>Competitive Prices</h5>
                           <p>Benefit from our wholesale rates on top-tier quality parts, offering you unbeatable value for your money.
                           </p>
                        </div>
                    </div>
                 </div>
             </div>
         </div>
     </section>

     <section id="newslettar">
  <div className="newslettar wrapper">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="text-content text-center pb-4">
            <h2>Connect With Us</h2>
            <p></p>
          </div>
          <div className="contact-details text-center">
            <div className="contact-item mb-3">
              <h4>Address</h4>
              <p>130 N Gould ST STE R, Sheridan, WY 82801, USA</p>
            </div>
            <div className="contact-item mb-3">
              <h4>Email</h4>
              <p><a href="mailto:info@vahanautos.com">support@vahanautossalvage.com</a></p>
            </div>
            <div className="contact-item mb-3">
              <h4>Phone Number</h4>
              <p><a href="tel:+18001234567">+1 (844) 661-0254</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      <Footer />
    </>
  );
}

export default Contact;

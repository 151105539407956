import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Warranty = () => {
  return (
    <>
    <Navbar />

    <section id="explore-food">
     <div class="explore-food wrapper">
       <div class="container">
         <div class="row">
           <div class="col-sm-12">
             <div class="text-content text-center">
              <h2>30-Day Warranty on Exclusive Auto Parts</h2>
              <p>At Vahan Autos Salvage LLC, we stand by the quality of our products. Our 30-day warranty covers damaged parts and products delivered erroneously. This warranty is parts-only and does not cover labor costs.</p>
              <p>If a part is defective, we will first attempt to replace it. If a replacement is not possible, we will refund the entire purchase price. If you place a reorder before returning the first part, we will charge for the second part and reimburse the first order upon its return. Return postage costs will not be reimbursed. Please provide a comprehensive explanation when returning a part. If the returned part does not meet warranty specifications, we reserve the right to charge a 25% restocking fee and deduct shipping costs from the refund. We are not responsible for items delivered to the address provided at the time of sale but not taken into possession by the client, as evidenced by tracking information. Items ordered for testing purposes or improperly ordered by the customer are not returnable. Before ordering a part, check with a mechanic or an auto part expert to ensure it’s the right one. Items returned after the warranty period has expired will not be refunded and will be returned to the sender. Parts must be returned within 30 days of the original shipping delivery date to be accepted.</p>
             <h3>Warranty Exceptions</h3>
             <p>Engines: Only the long block is guaranteed. This includes the engine assembly without manifolds, gaskets, seals, hoses, oil pan, timing belts, fuel injection, or carburetor. Additional parts like turbos, starters, air compressors, alternators, power steering pumps, optical distributors, and electrical water pumps are not covered unless purchased individually. High-wear parts like water pumps, distributor caps, spark plugs, wires, and timing chains are not covered.</p>
             <p>Instrument Clusters and Speedometers: Mileage is not guaranteed. These units may require reprogramming by a dealership, especially for brands like Lexus, Audi, BMW, Mercedes, and Cadillac. Returns or refunds are not accepted unless there is documented evidence from an Authorized Dealer that the part was reprogrammed and still not functioning properly.</p>
             <p>Carburetors: Guaranteed rebuildable, but not for normal wear and tear such as bent rotors, which are guaranteed to be machinable.</p>
             <p>Cylinder Heads: Guaranteed not to be deformed or cracked, though rings and valves may need re-machining. "Block Only" (Short or Long Block) orders are guaranteed rebuildable.</p>
             <p>Electrical Components: Engine computers, body control modules, transmission modules, and transfer case modules are guaranteed to have the exact manufacturer component number but are not sold for testing purposes. All electrical components have a one-year replacement warranty. Up to one replacement will be given for a non-working module, which may need resetting by a dealer.</p>
             <p>Struts: Sold with a one-year warranty if in good operating order. Riding quality is not guaranteed. Control arms are guaranteed to be true and straight, but parts like bushings and ball joints are not warranted.</p>
             <p>Transmissions: Guaranteed to shift properly with good gears and bearings. Before installation, replace all seals and gaskets, flush and flow-test coolers and lines, and engage the torque converter in the front pump. Manual transmissions require replacing the clutch, pressure plate, and slave cylinder, and checking the flywheel. Turbocharger seals may need replacing during the warranty term.</p>
             <p>Body Parts: Doors are full assemblies but may not include accessories like windows or handles. Only the door shell is guaranteed. Other body parts like hoods and fenders are not guaranteed to include additional accessories. Color matches are not guaranteed; body parts should be repainted to match your vehicle’s color.</p>
             <h3>Return Policy</h3>
             <p>Items modified or disassembled for testing are not returnable. Parts not matching the shipped condition (unless damaged in transit and verified) are not refundable. Parts must be uniquely identified by markings. All issues must be documented in writing via mail or email.</p>
             <p>Customer Service: Contact us at +1 (844) 243-8423 for any questions. Do not return parts without receiving an RMA. The customer is responsible for return shipping and must provide a return tracking number. Parts must be returned within 7 business days of receiving the RMA to the specified address. Upon receipt and inspection, we will issue a refund or send a replacement part.</p>
             <p>We stand by our guarantees and aim to provide peace of mind when purchasing used auto components from Vahan Autos Salvage LLC.</p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>

    <Footer />
    </>
  )
}

export default Warranty
import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Copyright = () => {
  return (
    <>
    <Navbar />

<section id="explore-food">
 <div class="explore-food wrapper">
   <div class="container">
     <div class="row">
       <div class="col-sm-12">
         <div class="text-content text-center">
          <h2>Copyright Ownership - Vahan Autos Salvage LLC</h2>
          <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of VAHAN AUTO SALVAGE LLC or its content suppliers and protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of VAHAN AUTO SALVAGE LLC.</p>
          <h3>Copyright Use</h3>
          <p>The content on vahanautoparts.com may not be used, reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited, in full or in part, for any purpose without our express, written consent.</p>
         <h3>Copyright Permissions</h3>
         <p>If you wish to use any material from our site, including, but not limited to, text, images, or digital content, please contact us at permissions@vahanautosparts.com to obtain permission.</p>
         <h3>Intellectual Property Rights</h3>
         <p>VAHAN AUTOS SALVAGE LLC respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with the following information:</p>
         <p>A description of the copyrighted work that you claim has been infringed;</p>
         <p> A description of where the material that you claim is infringing is located on the site;</p>
         <p>Your address, telephone number, and email address;</p>
         <p>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
         <p>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
         <h3>Copyright Contact</h3>
         <p>For any questions or concerns regarding the copyright policy or to file a copyright complaint, please contact us at copyright@vahanautosparts.com.</p>
        
         </div>
       </div>
     </div>
   </div>
 </div>
</section>

<Footer />
    
    </>
  )
}

export default Copyright
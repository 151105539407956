import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Img1 from '../images/img/img-1.png'
import Img2 from '../images/img/img-2.png'
import Img9 from '../images/img/why.jpg'

const About = () => {
  return (
    <>
    <Navbar />

    <section id="about">
     <div class="about-section wrapper">
       <div class="container">
         <div class="row align-items-center">
           <div class="col-lg-7 col-md-12 mb-lg-0 mb-5">
             <div class="card border-0">
               <img decoding="async" src={Img1} class="img-fluid" />
             </div>
           </div>
           <div class="col-lg-5 col-md-12 text-sec">
            <h2>About Us - Vahan Autos Salvage LLC</h2>
            <p>Welcome to Vahan Autos Salvage LLC, your ultimate destination for all automotive needs. We pride ourselves on being the most comprehensive hub for vehicle components, offering an extensive array of auto parts, import pieces, and accessories. Whether you're seeking OEM parts, aftermarket options, or meticulously refurbished and rebuilt components, we've got you covered.</p>
              <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free</a>
           </div>
         </div>
       </div>
       <div class="container food-type">
         <div class="row align-items-center">
           <div class="col-lg-5 col-md-12 text-sec mb-lg-0 mb-5">
            <h2>At Vahan Autos Salvage LLC</h2>
            <p>Quality is our top priority. We source our parts from renowned manufacturers and suppliers, ensuring you receive only the best. Our digital storefront is renowned for providing top-tier quality at wholesale rates, with the added assurance of a full warranty on our products. Our catalogs are constantly updated to feature the latest and most dependable brands in the industry, making it easier than ever to find exactly what you need.</p>
            <ul class="list-unstyled py-3">
              <li>Extensive Selection: Offering a vast range of auto parts, from OEM and aftermarket options to meticulously refurbished components, ensuring we meet every automotive need.</li>
              <li>Quality Assurance: Sourcing from renowned manufacturers and suppliers, we provide top-tier parts at wholesale rates, backed by a full warranty for your peace of mind.</li>
              <li>Convenient Shopping: Our constantly updated digital storefront makes finding and purchasing auto parts effortless, providing an unmatched shopping experience from the comfort of your home.</li>
            </ul> 
            <a href="tel:+18442438423" className="main-btn">Call Us Toll-Free</a>
           </div>
           <div class="col-lg-7 col-md-12">
             <div class="card border-0">
               <img decoding="async" src={Img2} class="img-fluid" />
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>


   <section class="services" id="services">
         <div class="sec-content">
             <div class="sec-title">
                <h2>Why Choose Us?</h2>
                <p>So much to offer</p>
             </div>
             <div class="service-content">
                 <div class="left">
                     <img decoding="async" src={Img9} alt="betheme" />
                 </div>
                 <div class="right">
                     <div class="card">
                         <div class="card-icon">
                         <i class="fas fa-shopping-cart"></i>
                         </div>
                         <div class="card-title">
                            <h5>Buy With Confidence</h5>
                            <p>We offer premium quality parts with a full warranty, ensuring you can shop with complete peace of mind.
                            </p>
                         </div>
                     </div>
                     <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-undo"></i>
                        </div>
                        <div class="card-title">
                           <h5>30 Days Returns</h5>
                           <p>Enjoy a hassle-free 30-day return policy, making your shopping experience worry-free and convenient.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-phone"></i>
                        </div>
                        <div class="card-title">
                           <h5>Call To Order</h5>
                           <p>Our knowledgeable team is ready to assist you with your orders, providing personalized support over the phone.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-shipping-fast"></i>
                        </div>
                        <div class="card-title">
                           <h5>Fast Delivery</h5>
                           <p>Experience swift and reliable delivery services, getting your parts quickly and efficiently to your doorstep.
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-check"></i>
                        </div>
                        <div class="card-title">
                           <h5>Huge Selections</h5>
                           <p>Access an extensive range of auto parts, from OEM to aftermarket options, ensuring you find exactly what you need. 
                           </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                        <i class="fas fa-dollar"></i>
                        </div>
                        <div class="card-title">
                           <h5>Competitive Prices</h5>
                           <p>Benefit from our wholesale rates on top-tier quality parts, offering you unbeatable value for your money.
                           </p>
                        </div>
                    </div>
                 </div>
             </div>
         </div>
     </section>

    <Footer />
    </>
  )
}

export default About